// 压缩图片
const compress = function(file) {
    return new Promise(function (resolve, reject) {
        // 创建一个reader
        let reader = new FileReader();
        // 将图片2将转成 base64 格式
        reader.readAsDataURL(file);
        // 读取成功后的回调
        try {
            reader.onloadend = function (res) {
                let img = new Image();
                img.src = this.result;
                img.onload = function () {
                    let canvas = document.createElement("canvas");
                    let ctx = canvas.getContext("2d");
                    let tCanvas = document.createElement("canvas");
                    let tctx = tCanvas.getContext("2d");
                    let width = img.width;
                    let height = img.height;
                    let ratio;
                    if ((ratio = (width * height) / 2000000) > 1) {
                        ratio = Math.sqrt(ratio);
                        width /= ratio;
                        height /= ratio;
                    } else {
                        ratio = 1;
                    }
                    canvas.width = width;
                    canvas.height = height;
                    //    铺底色
                    ctx.fillStyle = "#fff";
                    ctx.fillRect(0, 0, canvas.width, canvas.height);
                    //如果图片像素大于100万则使用瓦片绘制
                    let count;
                    if ((count = (width * height) / 1000000) > 1) {
                        count = ~~(Math.sqrt(count) + 1); //计算要分成多少块瓦片
                        // 计算每块瓦片的宽和高
                        let nw = ~~(width / count);
                        let nh = ~~(height / count);
                        tCanvas.width = nw;
                        tCanvas.height = nh;
                        for (let i = 0; i < count; i++) {
                            for (let j = 0; j < count; j++) {
                                tctx.drawImage(
                                img,
                                i * nw * ratio,
                                j * nh * ratio,
                                nw * ratio,
                                nh * ratio,
                                0,
                                0,
                                nw,
                                nh
                                );
                                ctx.drawImage(tCanvas, i * nw, j * nh, nw, nh);
                            }
                        }
                    } else {
                        ctx.drawImage(img, 0, 0, width, height);
                    }
                    canvas.toBlob(function(res) {
                        resolve(res)
                    }, "image/jpeg", 0.9);
                    tCanvas.width = tCanvas.height = canvas.width = canvas.height = 0;
                };
            };
        } catch(res) {
            resolve('')
        }
    })
}
export default compress;