<template>
  <div class="chat-body" @click="close($event)">
    <navbar :title="nickName"></navbar>
    <div class="chat-section">
      <div class="chat-scroll-list">
        <van-pull-refresh
          class="chat-scroll"
          ref="chatScroll"
          v-model="refresherFlag"
          @refresh="loadMoreData"
          :finished="isCompleted"
        >
          <div class="chat-content">
            <div v-for="(item, index) in messageList" :key="index">
              <div class="chat-time" v-if="timeShow(item.clientSequence)">
                {{ item.time | formatDate }}
              </div>
              <div
                class="chat-left"
                v-if="item.from != currentChatId && item.payload"
              >
                <div class="user-avatar">
                  <img
                    :src="
                      item.avatar ||
                      require('@/assets/images/chat/avatar.png')
                    "
                  />
                </div>
                <div class="left-bubble" v-if="item.type == 'TIMTextElem'">
                  <template
                    v-for="(i, ind) in decodeText(
                      item.payload.text || item.payload.Text
                    )"
                  >
                    <div v-if="i.name == 'text'" :key="ind">{{ i.text }}</div>
                    <img
                      v-else-if="i.name == 'img'"
                      :src="i.src"
                      class="bubble-emoji"
                    />
                  </template>
                </div>
                <div
                  class="left-bubble img-bubble"
                  v-if="item.type == 'TIMCustomElem'"
                >
                  <img
                    :src="item.payload.data || item.payload.Data"
                    class="bubble-img"
                    mode="widthFix"
                    @click="prviewImg(item.payload.data || item.payload.Data)"
                  />
                </div>
              </div>
              <div
                class="chat-right"
                v-if="item.from == currentChatId && item.payload"
              >
                <div class="user-avatar">
                  <img
                    :src="
                      item.avatar ||
                      require('@/assets/images/chat/avatar.png')
                    "
                  />
                </div>
                <div class="right-bubble" v-if="item.type == 'TIMTextElem'">
                  <template
                    v-for="(i, ind) in decodeText(
                      item.payload.text || item.payload.Text
                    )"
                  >
                    <div v-if="i.name == 'text'" :key="ind">{{ i.text }}</div>
                    <img
                      v-else-if="i.name == 'img'"
                      :src="i.src"
                      class="bubble-emoji"
                    />
                  </template>
                </div>
                <div
                  class="right-bubble img-bubble"
                  v-if="item.type == 'TIMCustomElem'"
                >
                  <img
                    :src="item.payload.data || item.payload.Data"
                    class="bubble-img"
                    @click="prviewImg(item.payload.data || item.payload.Data)"
                  />
                </div>
              </div>
            </div>
          </div>
          <div id="last"></div>
        </van-pull-refresh>
      </div>
    </div>
    <div class="chat-operation">
      <div class="chat-bar">
        <input
          type="text"
          v-model="text"
          :class="['text-input', text != '' ? 'in-input' : '']"
          placeholder="输入您想写的话..."
          placeholder-class="custom-placeholder"
          @keydown="keydown"
          @focus="focus"
        />
        <div class="right-operation">
          <div class="chat-emoji" @click="doShowEmoji">
            <img src="../../assets/images/chat/chat_emoji_icon.png" />
          </div>

          <div class="chat-add" v-show="text == ''" @click="doShowMenu">
            <img src="../../assets/images/chat/chat_add_icon.png" />
          </div>
          <div class="chat-btn" v-show="text != ''">
            <button type="default" class="send-btn" @click="doSendMsg">
              发送
            </button>
          </div>
        </div>
      </div>
      <div class="emoji-panel" v-show="showEmoji">
        <div
          class="emoji-item"
          v-for="item in emojiName"
          :key="item"
          @click="chooseEmoji(item)"
        >
          <img :src="emojiUrl + emojiMap[item]" />
        </div>
      </div>
      <div class="chat-menu" v-show="showMenu">
        <van-uploader :accept="imageAccept" :preview-image="false" :before-read="beforeImgRead" :after-read="afterImgRead">
          <div class="chat-menu-item">
            <div class="menu-item-sec">
              <img src="../../assets/images/chat/chat_menu_pic.png" />
            </div>
            <div class="menu-item-name">图片</div>
          </div>
        </van-uploader>

        <!-- <div class="chat-menu-item" @click="dosendFile">
          <div class="menu-item-sec">
            <image src="../../assets/images/chat/chat_menu_file.png"></image>
          </div>
          <div class="menu-item-name">
            文件
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { TIM } from "@/lib/tim/tim.js";
import Navbar from "@/components/navbar.vue";
import { emojiMap, emojiName, emojiUrl } from "@/lib/tim/emojiMap.js";
import compress from '@/lib/compressed-pic.js'
import uploadFile from '@/lib/upload.js'
import { mapState, mapGetters } from "vuex";
import { chatRecord, setRead, myInfo } from "@/api/im";
import { ImagePreview } from 'vant';

export default {
  name: "chatDetail",
  components: {
    Navbar,
  },
  data() {
    return {
      text: "",
      toUserId: "",
      showMenu: false,
      showEmoji: false,
      scrollInto: "last",
      messageList: [],
      nextReqMessageID: "",
      isCompleted: false,
      shaking: true,
      ReadyFlag: false,
      refresherFlag: false,
      emojiMap: emojiMap,
      emojiName: emojiName,
      emojiUrl: emojiUrl,
      conversationID: "",
      chatTitle: "C2C",
      nickName: "",
      myAccount: {},
      imageAccept: '.png, .jpg, .jpeg',
      basePath: 'im/',
      sendIng: false,
      lastListId: 0,
      toAccount: {},
      lastMessageId: ''
    };
  },
  computed: {
    ...mapState("chat", ["currentChatId"]),
    ...mapGetters({
      getTimReady: "chat/getTimReady",
    }),
    decodeText() {
      return function (text) {
        let renderDom = [];
        let temp = text;
        let left = -1;
        let right = -1;
        while (temp !== "") {
          left = temp.indexOf("[");
          right = temp.indexOf("]");
          switch (left) {
            case 0:
              if (right === -1) {
                renderDom.push({
                  name: "text",
                  text: temp,
                });
                temp = "";
              } else {
                let _emoji = temp.slice(0, right + 1);
                if (this.emojiMap[_emoji]) {
                  renderDom.push({
                    name: "img",
                    src: this.emojiUrl + this.emojiMap[_emoji],
                  });
                  temp = temp.substring(right + 1);
                } else {
                  renderDom.push({
                    name: "text",
                    text: "[",
                  });
                  temp = temp.slice(1);
                }
              }
              break;
            case -1:
              renderDom.push({
                name: "text",
                text: temp,
              });
              temp = "";
              break;
            default:
              renderDom.push({
                name: "text",
                text: temp.slice(0, left),
              });
              temp = temp.substring(left);
              break;
          }
        }
        return renderDom;
      };
    },
    timeShow() {
      return function (sequence) {
        let sequencelen = String(sequence).length;
        let sequenceInd = String(sequence).substring(
          sequencelen - 4,
          sequencelen
        );
        if (sequenceInd == "0001") {
          return true;
        } else {
          return false;
        }
      };
    },
  },
  filters: {
    formatDate(time, sequence) {
      let oldTime = parseInt(time);
      let nowTime = parseInt(new Date().getTime() / 1000);
      let oldDate = new Date(oldTime * 1000);
      let nowDate = new Date(nowTime * 1000);

      let Y = oldDate.getFullYear();
      let m = oldDate.getMonth() + 1;
      let d = oldDate.getDate();
      let H = oldDate.getHours();
      let i = oldDate.getMinutes();

      function zeroize(num) {
        return num < 10 ? "0" + num : num;
      }

      let timeDiff = nowTime - oldTime; //相差秒数
      let timemsg = "";
      if (timeDiff < 60) {
        timemsg = "刚刚";
      } else if (timeDiff < 3600) {
        timemsg = Math.floor(timeDiff / 60) + "分钟前";
      } else if (
        nowDate.getFullYear() === Y &&
        nowDate.getMonth() + 1 === m &&
        nowDate.getDate() === d
      ) {
        timemsg = "今天 " + zeroize(H) + ":" + zeroize(i);
      } else {
        let yesterday = new Date((nowTime - 86400) * 1000);
        if (
          yesterday.getFullYear() === Y &&
          yesterday.getMonth() + 1 === m &&
          yesterday.getDate() === d
        ) {
          timemsg = "昨天 " + zeroize(H) + ":" + zeroize(i);
        } else if (nowDate.getFullYear() === Y) {
          timemsg =
            zeroize(m) +
            "月" +
            zeroize(d) +
            "日 " +
            zeroize(H) +
            ":" +
            zeroize(i);
        } else {
          timemsg =
            Y +
            "年" +
            zeroize(m) +
            "月" +
            zeroize(d) +
            "日 " +
            zeroize(H) +
            ":" +
            zeroize(i);
        }
      }
      return timemsg;
    },
  },
  created() {
    this.toUserId = this.$route.query.cid;
    if (this.toUserId) this.conversationID = this.chatTitle + this.toUserId;
    this.nickName = this.$route.query.cname;
    this.getInfo();
    this.load();
    this.getDate();
    this.scrollToBottom();
  },
  beforeDestroy() {
    this.$tim.off(TIM.EVENT.SDK_READY, this.readyFun);
    this.$tim.off(TIM.EVENT.MESSAGE_RECEIVED,this.receivedFun);
    this.$tim.off(TIM.EVENT.SDK_NOT_READY, this.notReadyFun);
    this.$tim.off(TIM.EVENT.KICKED_OUT, this.kickedOutFun);
  },
  methods: {
    getInfo() {
      myInfo({to_chat_id: this.toUserId})
      .then((res) => {
        if (res.code == 0 && res.data) {
          this.myAccount = res.data.my;
          this.toAccount = res.data.to;
        }
      });
    },
    load() {
      let that = this;
      if (this.getTimReady) {
        that.getMessageList(that.toUserId);
      } else {
        this.$tim.on(TIM.EVENT.SDK_READY, that.readyFun);
      }
      this.$tim.on(TIM.EVENT.MESSAGE_RECEIVED, that.receivedFun);
      this.$tim.on(TIM.EVENT.SDK_NOT_READY, that.notReadyFun);
      this.$tim.on(TIM.EVENT.KICKED_OUT, that.kickedOutFun);
    },
    readyFun(event) {
      let that = this;
      that.getMessageList(that.toUserId);
      that.setTimReady(true);
    },
    receivedFun(event) {
      let that = this;
      if (that.messageList) {
        if (event.data[0].from == that.toUserId && event.data[0].ID != this.lastMessageId) {
          this.lastMessageId = event.data[0].ID
          let tmp = {...event.data[0]}
          if (this.toAccount.avatar) {
            tmp.avatar = this.toAccount.avatar
          }
          that.messageList.push(tmp);
          that.scrollToBottom();
          that.read();
        }
      }
    },
    notReadyFun() {
      this.setTimReady(false);
      this.load();
    },
    kickedOutFun() {
      this.$toast("被踢下线,请重新登录");
      this.setTimReady(false);
    },
    setTimReady(v) {
      this.$store.commit("chat/setTimReady", v);
    },
    getMessageList(toUserId) {
      chatRecord({ chat_id: toUserId }).then((res) => {
        if (res.code == 0) {
          this.messageList = this.processingData(res.data);
          if (res.data.length == 0) {
            this.isCompleted = true;
          } else {
            this.lastListId = res.data[0]?.id
          }
          this.read();
        }
      });
    },
    processingData(data) {
      data.reverse();
      return data.map((item) => {
        let msg_content = JSON.parse(item.msg_content);
        msg_content = msg_content[0];
        var time = item.created_time.replace(/\-/g, "/");
        return {
          clientSequence: item.msg_seq,
          from: item.from_chat_id,
          time: new Date(time).getTime() / 1000,
          avatar: item.avatar || "",
          nick: item.nickname || "",
          type: msg_content?.MsgType || "TIMTextElem",
          payload: msg_content?.MsgContent,
        };
      });
    },
    doShowMenu() {
      if (this.showEmoji) {
        this.showEmoji = false;
      }
      this.showMenu = !this.showMenu;
      this.scrollToBottom();
    },
    doShowEmoji() {
      if (this.showMenu) {
        this.showMenu = false;
      }
      this.showEmoji = !this.showEmoji;
      this.scrollToBottom();
    },
    chooseEmoji(item) {
      this.text += item;
    },
    doSendMsg() {
      if (this.sendIng) return false;
      let that = this;
      if (that.text == "") {
        this.$toast("请输入信息");
        return false;
      }
      this.sendIng = true
      let message = this.$tim.createTextMessage({
        to: that.toUserId,
        conversationType: TIM.TYPES.CONV_C2C,
        payload: {
          text: that.text,
        },
      });
      this.$tim
        .sendMessage(message)
        .then((res) => {
          this.sendIng = false
          let tmp = {...res.data.message}
          that.text = "";
          if (this.myAccount.avatar) {
            tmp.avatar = this.myAccount.avatar;
          }
          that.messageList.push(tmp);
          that.scrollToBottom();
        })
        .catch((err) => {
          this.sendIng = false
          this.$toast("发送失败");
        });
    },
    beforeImgRead(file) {
      if (['image/jpeg', 'image/jpg', 'image/png'].indexOf(file.type) == -1) {
        this.$toast('请上传 jpg,jpeg,png 格式的图片');
        return false;
      }
      return true;
    },
    async afterImgRead(file) {
      let that = this
      let uploadFile = file.file
      if (file.file.size > 2*1024*1024) {
        this.$toast('图片压缩中...');
        let blob = await compress(uploadFile);
        if (blob == '') {
          return this.$toast('图片压缩失败，请重新选择');
        }
        blob.name = uploadFile.name
        uploadFile = blob
      }
      let tmpFilePath = await this.upload(uploadFile)
      if(tmpFilePath == '') {
        this.showOverlay = false
        return this.$toast('图片上传失败')
      } else {
        let message = this.$tim.createCustomMessage({
          to: this.toUserId,
          conversationType: TIM.TYPES.CONV_C2C,
          payload: {
            data: tmpFilePath,
            description: 'photo'
          }
        });
        that.$tim.sendMessage(message)
          .then(res => {
            let tmp = {...res.data.message}
            if (that.myAccount.avatar) {
              tmp.avatar = that.myAccount.avatar;
            }
            that.messageList.push(tmp)
            that.scrollToBottom();
          }).catch(err => {
            console.log(err)
            return this.$toast('发送失败')
          });
      }
    },
    async upload(file) {
      let time = new Date().getTime().toString();
      var fileName = this.basePath + `${this.nowDate}` + '/' + this.currentChatId + time.substr(0, 10);
      if (file.type == "image/jpg" || file.type == "image/jpeg") {
          fileName += ".jpg";
      } else if (file.type == "image/png") {
          fileName += ".png";
      } else {
          fileName += ".jpg";
      }
      return await uploadFile(file, fileName, false)
    },
    // dosendFile() {
    // 	let that = this;
    // 	uni.chooseFile({
    // 		count: 1,
    // 		extension: ['.zip', '.doc'],
    // 		success: (fileres) => {
    // 			let message = that.$tim.createFileMessage({
    // 				to: that.toUserId,
    // 				conversationType: TIM.TYPES.CONV_C2C,
    // 				payload: {
    // 					file: fileres
    // 				},
    // 				onProgress: (event) => {}
    // 			});
    // 			that.$tim.sendMessage(message).then(res => {
    // 				console.log('发送成功')
    // 			}).catch(err => {
    // 				console.log(err)
    // 				uni.showToast({
    // 					title: '发送失败',
    // 					icon: 'none'
    // 				})
    // 			})
    // 		}
    // 	})
    // },
    scrollToBottom() {
      let that = this;
      this.$nextTick(() => {
        setTimeout(() => {
          let dom = document.querySelector(".chat-scroll");
          if (dom) {
            window.scrollTo(0, dom.scrollHeight + 150);
          }
        }, 1500)
      });
    },
    loadMoreData() {
      let that = this;
      this.refresherFlag = true;
      if (this.shaking) {
        this.shaking = false;
        if (that.isCompleted) {
          setTimeout(() => {
            that.refresherFlag = false;
            that.shaking = true;
            this.$toast("暂无更多数据");
          }, 1000);
        } else {
          chatRecord({ chat_id: this.toUserId, id: this.lastListId }).then(
            (res) => {
              if (res.code == 0) {
                let list = this.processingData(res.data);
                this.messageList = [...list, ...this.messageList];
                if (res.data.length == 0) {
                  this.isCompleted = true;
                } else {
                  this.lastListId = res.data[0]?.id;
                }
                this.refresherFlag = false;
                this.shaking = true;
              }
            }
          );
        }
      }
    },
    read() {
      setRead({ chat_id: this.toUserId }).then((res) => {});
      this.$tim
        .setMessageRead({
          conversationID: this.conversationID,
        })
        .then((res) => {})
        .catch((err) => {});
    },
    getDate() {
      let myDate = new Date();
      let year = myDate.getFullYear();
      let month = myDate.getMonth() + 1;
      month = month < 10 ? "0" + month : month;
      let date = myDate.getDate();
      date = date < 10 ? "0" + date : date;
      this.nowDate = `${year}-${month}-${date}`;
    },
    prviewImg(url) {
      ImagePreview([url]);
    },
    keydown(event) {
      if (event.keyCode==13) {
        this.doSendMsg()
      }
    },
    close(event) {
      let dom  = document.querySelector('.chat-operation')
      if (dom) {
        if (!dom.contains(event.target)) {
          if (this.showEmoji || this.showMenu) {
            if (this.showEmoji) {
              this.showEmoji = false;
            } else {
              this.showMenu = false;
            }
            this.scrollToBottom();
          }
        }
      }
    },
    focus() {
      this.showEmoji = false;
      this.showMenu = false;
    }
  },
};
</script>

<style lang="scss" scoped>
page {
  height: 100vh;
}

.chat-body {
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.chat-section {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.chat-scroll-list {
  flex: 1;
  overflow: hidden;
  @include safe-area-inset-bottom(280px);
}

.chat-scroll {
  height: 100%;
}

.chat-content {
  padding: 20px 30px;
}

.chat-bar {
  height: 98px;
  padding: 0 30px;
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.custom-placeholder {
  font-size: 26px;
  color: #999999;
}

.text-input {
  width: 530px;
  height: 68px;
  padding: 15px 30px;
  box-sizing: border-box;
  background-color: #f6f6f6;
  border-radius: 100px;
  font-size: 26px;
  color: #333333;
  transition: width 0.5s ease-in-out;
  border: none;
}

.in-input {
  width: 470px;
}

.right-operation {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.chat-operation {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  @include safe-area-inset-bottom;
  background-color: #ffffff;
}

.chat-emoji,
.chat-add {
  width: 50px;
  height: 50px;
  margin-left: 30px;

  img {
    width: 100%;
    height: 100%;
  }
}

.chat-btn {
  margin-left: 20px;

  .send-btn {
    width: 120px;
    height: 68px;
    padding: 0;
    margin: 0;
    text-align: center;
    line-height: 68px;
    font-size: 28px;
    border-radius: 100px;
    font-weight: 500;
    color: #ffffff;
    background-color: #04a3fc;
    border: none;
  }
}

.chat-left {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 30px;
}

.user-avatar {
  width: 100px;
  height: 100px;
  border-radius: 100px;
  overflow: hidden;

  img {
    width: 100px;
    height: 100px;
  }
}

.left-bubble {
  max-width: 564px;
  margin-top: 8px;
  margin-left: 30px;
  padding: 20px 30px;
  box-sizing: border-box;
  font-size: 28px;
  color: #333333;
  background-color: #ffffff;
  border-radius: 10px;
  font-weight: 500;
  position: relative;
  word-wrap: break-word;
  word-break: break-all;
  // &::before {
  // 	content: '';
  // 	width: 30px;
  // 	height: 16px;
  // 	position: absolute;
  // 	left: -10px;
  // 	top: 20px;
  // 	background-color: #FFFFFF;
  // 	transform: rotate(39deg) skew(307deg, 19deg);
  // }
}

.chat-right {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 30px;
}

.right-bubble {
  max-width: 564px;
  margin-right: 30px;
  margin-top: 8px;
  padding: 20px 30px;
  box-sizing: border-box;
  font-size: 28px;
  background-color: #04a3fc;
  border-radius: 10px;
  position: relative;
  font-weight: 500;
  color: #fff;
  word-wrap: break-word;
  word-break: break-all;
  // &::before {
  // 	content: '';
  // 	width: 30px;
  // 	height: 16px;
  // 	position: absolute;
  // 	right: -10px;
  // 	top: 20px;
  // 	background-color: #E2EAF6;
  // 	transform: rotate(321deg) skew(53deg, 341deg);
  // }
}

.chat-time {
  text-align: center;
  font-size: 22px;
  color: #999999;
  padding-bottom: 30px;
}

.bubble-img {
  max-width: 360px;
  border-radius: 30px;
}

.img-bubble {
  padding: 16px;
}

.chat-menu {
  background-color: #ffffff;
  padding: 30px 10px 40px;
  border-top: 1px solid #ededed;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .chat-menu-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 20px;
  }

  .menu-item-sec {
    width: 130px;
    height: 130px;
    margin-bottom: 20px;
    background-color: #f7f7f7;
    border-radius: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    img {
      width: 50px;
      height: 50px;
    }
  }

  .menu-item-name {
    font-size: 26px;
    color: #333333;
  }
}

.emoji-panel {
  border-top: 1px solid #ededed;
  background-color: #ffffff;
  height: 257px;
  padding: 20px 20px 40px;
  box-sizing: border-box;
  overflow: scroll;
}

.emoji-item {
  display: inline-block;
  margin: 10px;

  img {
    width: 60px;
    height: 60px;
  }
}

.bubble-emoji {
  width: 40px;
  height: 40px;
}
</style>